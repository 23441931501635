import axios from "axios";

export const api = axios.create({
	headers: {
		"Content-type": "application/json"
	}
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("access_token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return Promise.resolve(config);
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Remove invalid access token from local storage
      localStorage.setItem("access_token", "");

      // Navigate user back to Login page
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);
