import {api} from "./APIService";
import config from "../config";


export class ProfileService {
	static getProfiles = (page_url, full_name, limit) => {
		return api.get(`${config.API_URL}/profiles`, {params: {page_url, full_name, limit}});
	}

	static deleteAccount = (account_id) => {
		return api.delete(`${config.API_URL}/accounts`, {params: {account_id}});
	}
}