import React, {useEffect} from 'react';
import {Typography} from "@material-ui/core";
import JaxLogo from './../icons/jax-logo.png';
import config from "../config";


function LandingPage() {

	useEffect(() => {
		document.title = "Connect Rideshare Accounts | Jax Rideshare Rentals 🚗💨";
		document.body.style.height = "100vh";
	}, []);

	const openArgyleLink = () => {
		window.Argyle.create({
			pluginKey: config.ARGYLE_PLUGIN_KEY,
			apiHost: config.ARGYLE_API_URL,
			// linkItems: ['lyft', 'uber'],
			onAccountCreated: ({accountId, userId, linkItemId}) => {
				console.log('Account created: ', accountId, ' User ID:', userId, ' Link Item ID:', linkItemId)
			},
			onAccountConnected: ({accountId, userId, linkItemId}) => {
				console.log('Account connected: ', accountId, ' User ID:', userId, ' Link Item ID:', linkItemId)
			},
			onAccountUpdated: ({accountId, userId, linkItemId}) => {
				console.log('Account updated: ', accountId, ' User ID:', userId, ' Link Item ID:', linkItemId)
			},
			onAccountRemoved: ({accountId, userId, linkItemId}) => {
				console.log('Account removed: ', accountId, ' User ID:', userId, ' Link Item ID:', linkItemId)
			},
			onUserCreated: ({userId, userToken}) => {
				console.log('User created: ', userId, 'User token:', userToken)
			},
			onClose: () => {
				console.log('Link closed')
			},
			onTokenExpired: updateToken => {
				console.log('Token expired')
				// generate your new token here (more info: https://argyle.com/docs/api-reference/user-tokens)
				// updateToken(newToken)
			}
		}).open();
	};

	return (
		<>
			<img src={JaxLogo} alt="Jax Rideshare Rentals 🚗💨" className="logo"/>
			<div className="center">
				<Typography variant="h4" className="headline">
					Let's connect your rideshare accounts
				</Typography>
				<Typography variant="h5" className="sub-headline">
					Jax Rideshare Rentals is working to serve you but we need more information!
					Please connect your rideshare accounts below.
				</Typography>
				<button className="connect-btn" onClick={openArgyleLink}>
					Connect Rideshare Accounts
				</button>
			</div>
		</>
	);
}

export default LandingPage;
