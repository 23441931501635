const development = {
	ARGYLE_PLUGIN_KEY: process.env.REACT_APP_ARGYLE_SANDBOX_PLUGIN_KEY,
	ARGYLE_API_URL: process.env.REACT_APP_ARGYLE_SANDBOX_API_URL,
	API_URL: process.env.REACT_APP_DEVELOPMENT_API_URL
};

const production = {
	ARGYLE_PLUGIN_KEY: process.env.REACT_APP_ARGYLE_PLUGIN_KEY,
	ARGYLE_API_URL: process.env.REACT_APP_ARGYLE_API_URL,
	API_URL: process.env.REACT_APP_PRODUCTION_API_URL
};

const config = process.env.REACT_APP_ENV === "production"
	? production
	: development;

export default {
	// Add common config values here
	...config
};
