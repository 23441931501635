import React, {useEffect, useState} from "react";
import {TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {store} from 'react-notifications-component';
import JaxLogo from "../icons/jax-logo.png";
import {LoginService} from "../services/LoginService";

const useStyles = makeStyles((theme) => ({
  grid: {
    justifyContent: "center",
    margin: "1em 0"
  }
}));


const displayError = (errorMessage) => {
  store.addNotification({
    title: "Error Message",
    message: errorMessage,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 7000,
      onScreen: true
    }
  });
}


function AdminLoginPage(props) {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    document.title = "Login | Jax Rideshare Rentals 🚗💨";
    document.body.style.backgroundColor = "#f2f6f9";
  }, []);

  const submitLogin = () => {
    setError("");

    LoginService.login(username, password).then(response => {
      const {access_token} = response.data;
      // Store access token in local storage
      localStorage.setItem("access_token", access_token);

      props.history.push('/admin');
    }).catch(error => {
      console.log("#######")
      console.log(error.response)
      console.log(error.response.data.message)
      console.log("#######")
      setError(error.response.data.message);
    });
  }

  return (
    <>
      <img src={JaxLogo} alt="Jax Rideshare Rentals 🚗💨" className="logo"/>
      <div className="center" style={{textAlign: "center"}}>
        <Typography variant="h4" className="headline">
          Admin Login
        </Typography>
        <div className="login-form">
          <TextField
            required
            type="text"
            label="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            variant="outlined"
          />
          <TextField
            required
            type="password"
            label="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            defaultValue=""
            variant="outlined"
          />
          {error && (
            <span className="error-message">{error}</span>
          )}
        </div>
        <button className="connect-btn" style={{margin: "auto"}} onClick={submitLogin}>
          Login
        </button>
      </div>
    </>
  );
}

export default AdminLoginPage;
