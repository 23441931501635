import {api} from "./APIService";
import config from "../config";

export class ReportService {
	static getReportByDate = (account_id, date) => {
		return api.get(config.API_URL, {params: {account_id, date}});
	}
	static getReportByCadence = (account_id, cadence) => {
		return api.get(config.API_URL, {params: {account_id, cadence}});
	}
}
