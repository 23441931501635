import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LandingPage from "./landing/LandingPage";
import AdminLoginPage from "./admin/AdminLoginPage";
import AdminPage from "./admin/AdminPage";

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/" exact>
					<LandingPage/>
				</Route>
				<Route path="/login" render={(props) => <AdminLoginPage {...props}/>}/>
				<Route path="/admin" exact>
					<AdminPage/>
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
