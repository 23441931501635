import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
	bottom: {
		color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	top: {
		color: '#1a90ff',
		animationDuration: '550ms',
		position: 'absolute',
		left: 0,
	},
	circle: {
		strokeLinecap: 'round',
	},
}));

function LoadingSpinner(props) {
	const classes = useStyles();

	return (
		<div className="flex-container">
			<Typography variant="h6" gutterBottom>
				{props.message}
			</Typography>
			<div className={classes.root}>
				<CircularProgress
					variant="determinate"
					className={classes.bottom}
					size={40}
					thickness={4}
					{...props}
					value={100}
				/>
				<CircularProgress
					variant="indeterminate"
					disableShrink
					className={classes.top}
					classes={{
						circle: classes.circle,
					}}
					size={40}
					thickness={4}
					{...props}
				/>
			</div>
		</div>
	);
}

export default LoadingSpinner;
