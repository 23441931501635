import React, {useEffect, useState} from "react";
import {ProfileService} from "../services/ProfileService";
import {
	Button,
	ButtonGroup,
	Container,
	Divider,
	Grid, IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {ReactComponent as Empty} from "../icons/empty.svg";
import LoadingSpinner from "./LoadingSpinner";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {makeStyles} from "@material-ui/core/styles";
import {ReportService} from "../services/ReportService";
import {saveAs} from "file-saver";
import DownloadingSpinner from "./DownloadingSpinner";
import moment from 'moment';
import config from "../config";
import {store} from 'react-notifications-component';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';


const PAGE_SIZE = 20;
const PAGE_SETTINGS = {
	count: 0,
	page: 0,
	size: PAGE_SIZE,
	previous: "",
	next: "",
	url: `${config.ARGYLE_API_URL}/profiles`
}


const useStyles = makeStyles((theme) => ({
	grid: {
		justifyContent: "center",
		margin: "1em 0"
	}
}));


const displayError = (errorMessage) => {
	store.addNotification({
		title: "Error Message",
		message: errorMessage,
		type: "danger",
		insert: "top",
		container: "top-right",
		animationIn: ["animate__animated", "animate__fadeIn"],
		animationOut: ["animate__animated", "animate__fadeOut"],
		dismiss: {
			duration: 7000,
			onScreen: true
		}
	});
}


function AdminPage() {
	const classes = useStyles();
	const [profiles, setProfiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [openCalendar, setOpenCalendar] = useState(false);
	const [fullNameQuery, setFullNameQuery] = useState(null);
	const [fullName, setFullName] = useState(null);
	const [selectedProfile, setSelectedProfile] = useState(null);
	const [pageSettings, setPageSettings] = useState(PAGE_SETTINGS);

	useEffect(() => {
		document.title = "Insurance Period Reports | Jax Rideshare Rentals 🚗💨";
		document.body.style.backgroundColor = "#f2f6f9";

		setLoading(true);
		ProfileService.getProfiles(pageSettings.url, fullName, PAGE_SIZE).then((response) => {
			const {count, previous, next, results} = response.data;

			setPageSettings(prevPageSettings => ({...prevPageSettings, count, previous, next}));
			setProfiles(results);
			setLoading(false);
		}).catch(error => {
			displayError(error.response.data);
			setLoading(false);
		});
	}, [pageSettings.url, fullName]);

	const getRecord = (record) => {
		if (!record) {
			return <Empty/>;
		} else {
			return record;
		}
	};

	const getInitials = (full_name) => {
		let initials = full_name.split(" ");

		if (initials.length > 1) {
			initials = initials.shift().charAt(0) + initials.pop().charAt(0);
		} else {
			initials = full_name.substring(0, 2);
		}

		return initials.toUpperCase();
	}

	const getReportByCadence = (fullName, accountId, cadence) => {
		setDownloading(true);

		ReportService.getReportByCadence(accountId, cadence).then(response => {
			// Initialize Blob object
			const blob = new Blob([response.data], {type: "text/csv"});
			// Save the CSV report locally
			saveAs(blob, `${fullName}-${cadence}-report.csv`);
			setDownloading(false);
		}).catch(error => {
			displayError(error.response.data);
			setDownloading(false);
		});
	}

	const handleReportByDate = (profile) => {
		setSelectedProfile(profile);
		setOpenCalendar(true); // Display calendar
	}

	const getReportByDate = (date) => {
		const {full_name, account} = selectedProfile;
		setDownloading(true);

		ReportService.getReportByDate(account, date).then(response => {
			// Initialize Blob object
			const blob = new Blob([response.data], {type: "text/csv"});
			// Save the CSV report locally
			saveAs(blob, `${full_name}-${date}-report.csv`);
			setDownloading(false);
			setSelectedProfile(null);
		}).catch(error => {
			displayError(error.response.data);
			setDownloading(false);
			setSelectedProfile(null);
		});
	}

	const handleChange = (event) => {
		setFullNameQuery(event.target.value);
	}

	const submitCalendar = (date) => {
		// Remove time from datetime
		date = date.format(moment.HTML5_FMT.DATE);
		getReportByDate(date);
	}

	const handlePageChange = (event, newPage) => {
		let newUrl;
		if (newPage > pageSettings.page) {
			// Display next page
			newUrl = pageSettings.next;
		} else {
			// Display previous page
			newUrl = pageSettings.previous;
		}

		setPageSettings(prevPageSettings => ({...prevPageSettings, page: newPage, url: newUrl}));
	}

	const handleSearch = () => {
		// Ensure Page Settings aren't reset without triggering useEffect
		if (fullName !== fullNameQuery) {
			setPageSettings(PAGE_SETTINGS);
		}
		setFullName(fullNameQuery);
	}

	const handleReset = () => {
		// Ensure Page Settings aren't reset without triggering useEffect
		if (fullName !== fullNameQuery) {
			setPageSettings(PAGE_SETTINGS);
		}
		setFullName(null);
		setFullNameQuery(null);
	}

	const deleteAccount = (accountId) => {
		setLoading(true);
		ProfileService.deleteAccount(accountId).then((response) => {
			// Locally remove profle from the list
			const profilesUpdated = profiles.filter(profile => profile.account !== accountId);
			setProfiles(profilesUpdated);
			setLoading(false);
		}).catch(error => {
			displayError(error);
			setLoading(false);
		});
	}

	const displayFilters = () => {
		return (
			<Grid container direction="row" spacing={2} className={classes.grid}>
				<Grid item md={4}>
					<TextField
						label="Full Name"
						id="full-name"
						variant="outlined"
						size="small"
						placeholder="e.g. John Doe"
						fullWidth={true}
						onChange={handleChange}
						value={fullNameQuery}
					/>
				</Grid>
				<Grid item>
					<Button variant="contained" color="primary"
							onClick={handleSearch} className="action-button"
							disableElevation>
						Search
					</Button>
					<Button variant="outlined" color="secondary"
							onClick={handleReset} className="action-button"
							style={{marginLeft: 10}}>
						Reset
					</Button>
				</Grid>
			</Grid>
		);
	}

	const displayTable = () => {
		if (loading === true) {
			return (
				<LoadingSpinner message="Loading Profiles"/>
			);
		} else if (profiles.length === 0) {
			return (
				<div className="flex-container">
					<Typography variant="h6" gutterBottom>
						No Profiles were found.
					</Typography>
				</div>
			);
		} else {
			return (
				<Grid container component={Paper} spacing={3} direction="column"
					  className="table-card">
					<Grid item>
						{displayFilters()}
					</Grid>
					<Divider/>
					<Grid item>
						<TableContainer>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell align="left">Full Name</TableCell>
										<TableCell align="left">Email</TableCell>
										<TableCell align="left">Platform</TableCell>
										<TableCell align="center">Download Reports (Last 12
											Months)</TableCell>
										<TableCell align="center" width={30}></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{profiles.map(profile => (
										<TableRow key={profile.id}>
											<TableCell component="th" scope="row">
												<div className="full-name-cell">
													{profile.picture_url ? (
														<img className="profile-img"
															 src={profile.picture_url}
															 alt="Profile"/>
													) : (
														<div className="initials">
															{getInitials(profile.full_name)}
														</div>
													)}
													{getRecord(profile.full_name)}
												</div>
											</TableCell>
											<TableCell align="left">{getRecord(profile.email)}</TableCell>
											<TableCell align="left">{getRecord(profile.employer)}</TableCell>
											<TableCell align="right" width="400">
												<ButtonGroup color="primary">
													<Button
														onClick={() => getReportByCadence(profile.full_name, profile.account, "daily")}>Daily</Button>
													<Button
														onClick={() => getReportByCadence(profile.full_name, profile.account, "weekly")}>Weekly</Button>
													<Button
														onClick={() => getReportByCadence(profile.full_name, profile.account, "monthly")}>Monthly</Button>
													<Button variant="contained" color="primary" disableElevation
														onClick={() => handleReportByDate(profile)}>Report By Date</Button>
												</ButtonGroup>
											</TableCell>
											<TableCell align="center">
												<IconButton onClick={() => deleteAccount(profile.account)}>
													<DeleteTwoToneIcon style={{ color: "red", fontSize: 32 }}/>
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
								<TablePagination
									component="div"
									count={pageSettings.count}
									page={pageSettings.page}
									onPageChange={handlePageChange}
									rowsPerPage={PAGE_SIZE}
									rowsPerPageOptions={[PAGE_SIZE]}
								/>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			);
		}
	}

	return (
		<>
			{downloading && <DownloadingSpinner message="Retrieving Report"/>}
			<Container maxWidth="lg">
				{displayTable()}
			</Container>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<DatePicker
					label="Pick Report Date"
					format="yyyy-MM-DD"
					onChange={() => null}
					onClose={() => setOpenCalendar(false)}
					onAccept={submitCalendar}
					open={openCalendar}
					TextFieldComponent={() => null}
					okLabel="Get Report"
					maxDate={new Date()}
				/>
			</MuiPickersUtilsProvider>
		</>
	);
}

export default AdminPage;
